/*  css */

/* common Css */

body {
  font-family: "Poppins", sans-serif;

  overflow: auto;
  background: #fff;
  text-decoration: none !important;
  list-style: none !important;
}

body::-webkit-scrollbar {
  display: none;
}

/* .page-content {
  overflow-y: scroll !important;
} */

h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 100%;
  color: #fff;
}

h2 {
  font-weight: 600;
  font-size: 25px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #000000;
}

h3 {
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0;
  color: #000;
}

h4 {
  font-size: 16px;
  line-height: 17px;
  color: #000;
  font-weight: 700;
}

h6 {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #000000;
}

.large_parah {
  font-size: 15px;
  letter-spacing: -0.02em;
  color: #000000;
  line-height: 20px;
}

.common-padding {
  padding: 80px 0;
}

p {
  letter-spacing: -0.02em;
  color: #000000;
  font-size: 12px;
}

.red_text {
  color: #cb8c2f;
}

.fw-500 {
  font-weight: 500;
}

.drk_parah {
  text-align: center;
  letter-spacing: -0.02em;
  color: #1b9fbc;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
}

li {
  list-style: none;
}

.title_signin .form-control:focus {
  background: #efefef;
  border: 0;
}

.title_signin .form-control::placeholder {
  color: #808080;
}

.form-control {
  background: #efefef;
  border: unset;
  padding: 14px 16px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 15px;
  color: #808080;
}

.black_parah {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #000000;
}

/* Common Css ENd */

/* Login Page */

.bg-grey-admin .form-check-input:checked {
  background-color: #1b95bc;
  border-color: #1b95bc;
}

.bg-grey-admin #adminForgotLink {
  color: #1b95bc;
}

.login_page .container,
.login_page .container-fluid,
.login_page .container-sm,
.login_page .container-md,
.login_page .container-lg,
.login_page .container-xl {
  width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  margin-right: auto;
  margin-left: auto;
}

.login_desc h4 {
  color: #1A1A1A;
}

.navbar_set {
  position: absolute;
  top: 20px;
  left: 40px;
  z-index: 99;
}

.btn-custom:hover {
  background: transparent;
  box-shadow: 14px 17px 40px rgba(112, 144, 176, 0.08);
  border-radius: 50px;
  padding: 10px 0px;
  border: none;
  opacity: 0.9;
  border: 1px solid #1b95bc;
  color: #1b95bc;
}

.btn-custom-border:hover {
  background: #1b95bc;

  box-shadow: 14px 17px 40px rgba(112, 144, 176, 0.08);
  border-radius: 50px;
  padding: 10px 0px;

  opacity: 0.9;
}

.sign_in {
  max-width: 470px;
}

.sign_in .card {
  border-radius: 10px;
}

.navbar-expand-lg .navbar-collapse {
  justify-content: flex-end;
}

.regs_tody h2 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
  color: #1a1a1a;
}

.bl-none {
  cursor: pointer;
  padding: 14px 16px;
  background: #e8f0fe;
  border: 0;
  height: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.form-group label {
  color: #333333;
  letter-spacing: 0.3px;
  font-size: 11px;
  line-height: 12px;
  padding: 0 0 0 16px;
}

.btn-custom {
  border-radius: 50px;
  padding: 10px 0px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  letter-spacing: -0.02em;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background: #1b95bc;
  border: 1px solid transparent;
}

.btn-custom-border {
  background: #fff;
  border-radius: 50px;
  padding: 10px 0px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 27px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  border: 0;
  color: #1b95bc;
  letter-spacing: -0.02em;
}

.login_desc p {
  font-size: 16px;
  line-height: 24px;
  color: #1A1A1A;
}

.regs_tody {
  margin: 34px 0 0 0;
}

.custom-switch label {
  font-size: 12px;
  line-height: 20px;
  color: #1a1a1a;
  letter-spacing: 0.3px;
  margin-left: 10px;
}

.frgt-pw a {
  color: #007aff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.navbar_set img {
  max-width: 160px;
}

.login_page .navbar_set img {
  width: 100%;
}

.wrapper_login {
  background-image: url("../images/login-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  align-items: center;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
}

.text_flex_end {
  justify-content: flex-end;
  display: flex;
  align-items: flex-end;
}

.sign_in {
  padding-bottom: 50px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup_wrapper .login_desc {
  width: 100% !important;
}

.signup_wrapper {
  background-image: url("../images/submit_register.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 200px 0px;
}

.submit_form .sign_in {
  padding-bottom: 50px;
  padding-top: 40px;
}

.submit_form {
  background-image: url("../images/submit_register.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.sign_in .card-body {
  padding: 80px 55px 30px;
  margin: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.submit_form .card-body {
  padding: 3.25em;
}

/* Login Page */

/* navbar */
.header_main {
  position: fixed;
  background: #fff;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
}

ul.navbar-nav li {
  margin: 0 50px;
}

.navbar-brand img {
  max-width: 140px;
  position: relative;
  z-index: 4;
}

.white-btn {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  background: #fff;
  font-size: 14px !important;
  color: #1b95bc !important;
  width: 130px;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 14px;
  text-align: center;
  font-weight: 600 !important;
  margin: 10px 0 0 0;
  cursor: pointer;
}

.navbar-nav .white-btn {
  color: #1b95bc !important;
  margin-top: 0;
}

.main_header nav.navbar.navbar-expand-lg.navbar-light {
  padding: 14px 5px;
}

.white-btn:hover {
  background: #1b95bc !important;
  color: #fff !important;
}

.white-btn:focus {
  border-color: 0 !important;
  box-shadow: none !important;
  background: #1b95bc !important;
  color: #fff !important;
}

.blck_header .btn.btn-primary.white-btn:hover {
  color: #fff !important;
}

ul.navbar-nav li a {
  font-size: 14px;
  line-height: 100%;
  color: #fff !important;
  letter-spacing: -0.02em;
  text-align: center;
  font-weight: 600;
}

header {
  position: absolute;
  width: 100%;
  z-index: 9;
}

.blck_header ul.navbar-nav li a {
  color: #000 !important;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

/* .navbar {
  padding: 25px 30px;
} */

ul.navbar-nav li:last-child {
  margin: 0 0 0 50px;
}

.dark-header a.nav-link {
  color: #1b95bc !important;
}

.dark-header a.navbar-brand img {
  max-width: 140px;
}

/* navbar */

/* Banner */
.banner-img {
  background-image: url("../images/banner/1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: initial;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.banner-img2 {
  background-image: url("../images/banner/2.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.gum-disease {
  background-image: url("../images/banner/6.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.JawProblems {
  background-image: url("../images/banner/4.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.MissingTeeth {
  background-image: url("../images/banner/3.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.PreventionEeducation {
  background-image: url("../images/banner/7.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.options_part a:hover {
  color: #000 !important;
}

.header_inner img {
  width: 140px;
}

.tooth-decay {
  background-image: url("../images/banner/5.png");
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
}

.tooth-decay h1 {
  color: #1b75bc;
}

.banner-img2 h1 {
  color: #1b75bc;
}

.gum-disease h1 {
  color: #1b75bc;
}

.JawProblems h1 {
  color: #1b75bc;
}

.MissingTeeth h1 {
  color: #1b75bc;
}

.banner-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.banner-content a {
  display: flex;
  justify-content: center;
  border-radius: 50px;
  margin: 60px 0 0 0;
  height: 50px;
  width: 420px;
  align-items: center;
}

.second-header .nav-item a {
  color: #000 !important;
}

.container_inner_width {
  padding: 0 0 0 190px;
}

.banner_btm {
  position: absolute;
  top: -145px;
  width: 100%;
  background: #fff;
  height: 65px;
  padding: 0 80px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.banner-content p {
  font-size: 17px;
  max-width: 600px;
}

.btm_logo img {
  max-width: 250px;
}

.btm_ques {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  width: 100%;
}

.btm_ques a {
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  color: #1b9fbc;
}

.btm_logo {
  position: relative;
  top: -19px;
}

.absolute-txt {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Banner */

.problems-content .inner_prblm_detail {
  padding: 10px;
}

.problems-content h3 {
  color: #fff;
}

section.tooth-aid-prblms {
  background: #fff;
}

section.about-us {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.watch-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

section.discover {
  background: #fff;
}

/* Logos section */
.logo-img {
  text-align: center;
}

.logo-img img {
  max-width: 170px;
}

section.logos {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

/* Logos section / End */

/* CTA Section */
section.cta-section {
  text-align: center;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.input-mail input[type="email"] {
  border: 0;
  max-width: 600px;
  height: 36px;
  background: #f5f5f5;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 12px;
  padding: 20px;
  width: 100%;
}

.input-mail input[type="mail"]:focus {
  outline: 0;
  border: 0;
}

.input-mail input[type="mail"]::placeholder {
  font-weight: 500;
  color: #000;
}

.input-mail input[type="submit"] {
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f5f5f5;
  padding: 0 20px;
  font-weight: 500;
  font-size: 12px;
}

/* CTA Section / End*/

/* Video Section */
.video-container .video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.videoCoverImage img {
  width: 100%;
}

.play-button-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  pointer-events: none;
}

.play-button-wrapper #circle-play-b {
  cursor: pointer;
  pointer-events: auto;
}

.play-button-wrapper #circle-play-b svg {
  width: 100px;
  height: 100px;
  fill: #fff;
  stroke: #fff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0.9;
}

/* Video Section / ENd*/

/* Quiz Section */

.progress_bar {
  width: 334px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 17px;
  margin: 0 auto;
}

.progress_bar p {
  background: #1b9fbc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 17px;
}

.ques-one {
  width: 36px;
}

.ques-two {
  width: 62px;
}

.ques-three {
  width: 92px;
}

.ques-four {
  width: 138px;
}

.ques-five {
  width: 183px;
}

.ques-six {
  width: 252px;
}

.ques-seven {
  width: 288px;
}

.ques-eight {
  width: 314px;
}

.ques-nine {
  width: 334px;
}

.img_text {
  display: inline-block;
}

section.question_bar h4 {
  font-size: 16px;
  color: #1b9fbc;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 10px 0;
}

.card_shadow {
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25) !important;
  margin: 0;
  padding: 60px 60px;
  height: 465px;
  border: 0 !important;
}

section.question_bar {
  margin: 90px 0;
}

.symptom-checker h2 {
  font-size: 25px;
}

.back_btn a {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
}

.symptom-checker {
  padding: 0 0 150px 0;
}

.option-btn {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 8px;
  background: #fff;
  font-size: 16px !important;
  color: #1b95bc !important;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 13px;
  text-align: center;
  font-weight: 600 !important;
  max-width: 220px;
  width: 100%;
  transition: 0.3s;
}

.option-btn:hover {
  background: #1b9fbc;
  color: #fff !important;
}

.inner-options {
  padding: 20px 0;
}

.title_signin h1 {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #1a1a1a;
}

.option-choose li {
  /* width: 32%; */
  width: 26%;
  text-align: center;
}

.max-options li {
  width: 25%;
  text-align: center;
}

.symptom-checker ul {
  display: flex;
  justify-content: center !important;
}

.w-49 {
  width: 43% !important;
}

/* .w-23{
  width: 23% !important;
} */
.large-text {
  padding: 5px 13px !important;
}

.dark_btn {
  background: #1b9fbc;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: #fff !important;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 8px;
  font-weight: 600;
  font-size: 16px;
  max-width: 160px;
  width: 100%;
}

.mt-100 {
  margin: 100px 0 0 0;
}

.p-60 {
  padding: 60px 0;
}

.options_part input[type="search"] {
  background: #ffffff;
  border-radius: 5px;
  border: 0;
  padding: 6px 20px;
  color: #1b9fbc;
  max-width: 500px;
  width: 100%;
  font-size: 12px;
}

.options_part input[type="search"]::placeholder {
  color: #1b9fbc;
  font-weight: 700;
}

.detail_page {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 10px;
  margin: 60px auto 0;
  max-width: 470px;
  width: 100%;
}

.detail_page .card-body {
  padding: 30px 55px;
  margin: 0;
  border-radius: 10px;
}

.inner-options .active {
  border-radius: 8px;
}

.lg_height {
  height: 800px;
}

.detail_page .btn_submit {
  margin: 95px 0 0 0;
}

.detail_page .title_signin {
  color: #1b9fbc;
}

section.cta-section p {
  font-weight: 500;
  margin: 14px 0 22px;
}

section.discover {
  padding: 50px 0 130px 0 !important;
}

.custom_btn {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  letter-spacing: -0.02em;
  color: #1b9fbc;
  padding: 10px 20px;
  max-width: 300px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  border-radius: 50px;
  display: inline-block;
}

.problem_selected img {
  border-radius: 8px;
  margin: 0 auto;
  display: block;
  max-width: 250px;
}

.problem_selected p {
  max-width: 660px;
  margin: 20px auto 0;
}

.img_text img {
  border-radius: 8px;
}

/* Quiz Section / End*/

/* Testimonial section */
figure img {
  width: 110px !important;
}

.testimonial .row .tabs li {
  all: unset;
  display: block;
  position: relative;
}

.carousel-indicators {
  justify-content: space-around !important;
}

.testimonial .row .tabs li:nth-child(1) {
  align-self: flex-end;
}

.testimonial .row .tabs li:nth-child(1)::before {
  left: 64%;
  bottom: -50px;
}

.testimonial .row .tabs li:nth-child(1)::after {
  left: 97%;
  bottom: -81px;
}

.testimonial .row .tabs li:nth-child(1) figure img {
  margin-left: auto;
}

.testimonial .row .tabs li:nth-child(2) {
  align-self: flex-start;
}

.testimonial .row .tabs li:nth-child(2)::before {
  right: -65px;
  top: 50%;
}

.testimonial .row .tabs li:nth-child(2)::after {
  bottom: 101px;
  border-radius: 50%;
  right: -120px;
}

.testimonial .row .tabs li:nth-child(2) figure img {
  margin-right: auto;
  max-width: 300px;
  width: 100%;
  margin-top: -50px;
}

.testimonial .row .tabs li:nth-child(3) {
  align-self: flex-end;
}

.testimonial .row .tabs li:nth-child(3)::before {
  right: -10px;
  top: -66%;
}

.testimonial .row .tabs li:nth-child(3)::after {
  top: -130px;
  border-radius: 50%;
  right: -46px;
}

.testimonial .row .tabs li:nth-child(3) figure img {
  margin-left: auto;
  margin-top: -50px;
}

.testimonial .row .tabs li figure {
  position: relative;
  cursor: pointer;
  margin: 0;
}

.testimonial .row .tabs li figure img {
  display: block;
}

.testimonial .row .tabs li figure:hover::after {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.testimonial .row .tabs.carousel-indicators li.active figure::after {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.testimonial .row .carousel > h3 {
  font-size: 20px;
  line-height: 1.45;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  margin-bottom: 0;
}

.testimonial .row .carousel h1 {
  font-size: 40px;
  line-height: 1.225;
  margin-top: 23px;
  font-weight: 700;
  margin-bottom: 0;
}

.testimonial .row .carousel .carousel-indicators {
  all: unset;
  padding-top: 43px;
  display: flex;
  list-style: none;
}

/* .testimonial .row .carousel .carousel-indicators li {
  background: #000;
  background-clip: padding-box;
  height: 2px;
} */

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper {
  margin-top: 42px;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper p {
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 79px 0 0 0;
}

.testimonial .row .carousel .carousel-inner .carousel-item .quote-wrapper h3 {
  margin-top: 10px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
}

.indicators li {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  margin: 0 4px;
  cursor: pointer;
  border: 1px solid #000;
}

.indicators li.active {
  background: #000;
}

.carousel-indicators.indicators {
  justify-content: center !important;
}

.carousel-indicators img {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  width: 80px !important;
}

.carousel-item img {
  width: 200px;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  display: block;
  margin: 0 auto;
}

section.testimonial {
  padding: 130px 0;
}

.carousel-indicators {
  justify-content: space-around !important;
}

.carousel-indicators.indicators {
  display: none !important;
}

/* Testimonial section / End*/

/* symptom-check-results */

.header_inner .white-btn {
  margin: 0;
}

/* sidebar */
.sidebar_tabs li {
  margin: 0 !important;
  width: 49%;
  padding: 6px 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

ul.sidebar_tabs li a {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 9px 4px;
  color: #1b9fbc !important;
  width: 110px;
  height: 110px;
}

.sidebar_tabs li.active a {
  background: #1b9fbc;
  color: #fff !important;
}

.active_main {
  color: #fff !important;
  background: #1b9fbc !important;
}

/* .active_main_s {
color: #fff !important;
  background: #1B9FBC !important;
} */
.sidebar_content {
  background: #ffffff;
  padding: 0;
}

.sidebar_tabs li img {
  width: 32px;
  display: block;
  height: 32px;
  margin: 20px auto 0;
}

.sidebar_tabs li span {
  text-align: center;
  letter-spacing: -0.02em;
  font-size: 12px;
  line-height: 12px;
  margin: 15px 0 0 0;
}

/* .profile_detail img {
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  max-width: 100px;
  width: 100%;
} */

.profile_detail span {
  color: #b0b9c5;
  font-size: 12px;
  margin: 4px 0 60px 0;
  font-weight: 600;
  display: block;
}

.profile_detail h3 {
  font-size: 16px;
  margin: 14px 0 0 0;
}

/* sidebar */

/* Main description area */

.desc_area {
  padding: 30px 20px;
  /* height: 100%; */
  position: relative;
}

section.main_content_wrapper .col-md-3 {
  border-right: 1px solid #f5f5f5;
}

section.main_content_wrapper .col-md-9 {
  background: #f5f5f5;
}

section.main_content_wrapper {
  height: 100vh;
}

section.main_content_wrapper .container-fluid {
  height: 100%;
}

section.main_content_wrapper .container-fluid .row {
  height: 100%;
}

section.main_content_wrapper .container-fluid .col-md-9 {
  height: 100%;
}

.problem_desc {
  background: #fff !important;
  margin: 3px 0 0 0;
  border: 0.25px solid #ccd2e3;
  box-shadow: 0px 4px 14px rgba(96, 98, 106, 0.05);
  border-radius: 0px 10px 10px 10px;
}

.common_shadow {
  background: #fff !important;
  margin: 3px 0 0 0;
  border: 0.25px solid #ccd2e3;
  box-shadow: 0px 4px 14px rgba(96, 98, 106, 0.05);
  border-radius: 10px;
}

ul.problem_tabs li {
  background: #ffffff;
  border: 0.25px solid #ccd2e3;
  border-radius: 5px 0px 0px 5px;
  margin: 3px 0;
  max-width: 225px;
  text-align: center;
  width: 100%;
}

ul.problem_tabs li a {
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1b9fbc;
  font-weight: 600;
  width: 100%;
  display: inline-block;
  padding: 15px 5px;
}

.main-title {
  color: #1b9fbc;
  padding: 36px 33px 27px;
  border-bottom: 0.25px solid rgba(204, 210, 227, 1);
  font-weight: 700;
  line-height: 52px;
}

.prblm_inner_content {
  padding: 64px 33px 27px;
}

.prblm_inner_content h3 {
  text-align: left;
  margin: 0 0 20px 0;
}

.next_page_btn a {
  max-width: 151px;
  width: 100%;
  font-size: 12px;
}

.next_page_btn {
  text-align: right;
  margin: 50px 0 0 0;
}

.drk_color {
  color: #000000;
}

.questions_part {
  padding: 15px 33px 27px;
}

.ques_detail-inner {
  background: rgba(27, 117, 188, 0.25);
  border: 0.2px solid #ccd2e3;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 7px 20px 7px 25px;
}

.options_part ul li {
  border-radius: 8px;
  margin: 0 0 0 16px;
}

.ques_detail p {
  font-weight: 500;
  font-size: 12px;
}

.disabled {
  background: #cacedb;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.result_part {
  padding: 27px 33px 90px;
}

/* Main description area / end */

/* symptom-check-results / End */

/* Account home */
.table > :not(caption) > * > * {
  border: 0;
  background: #fff;
  box-shadow: none;
  padding: 0 0 10px 0;
}

.table {
  margin: 0;
}

.card {
  margin-bottom: 0;
  border: 0.25px solid #ccd2e3;
  box-shadow: 0px 4px 14px rgba(96, 98, 106, 0.05);
  border-radius: 10px;
}

tr.box_prop {
  background: #ffffff;
  border-radius: 5px;
}

tr.box_prop td p {
  border: 0.25px solid #ccd2e3;
  margin: 0;
  padding: 10px 16px;
  border-right: 0;
  border-left: 0;
  font-weight: 500;
  display: flex;
  align-items: center;
}

tr.box_prop td:first-child p {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 0.25px solid #ccd2e3;
  border-right: 0;
}

tr.box_prop td:last-child p {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 0.25px solid #ccd2e3;
  border-left: 0;
  padding: 10px 16px 10px 0;
}

.view_btn {
  color: #1b9fbc;
  text-decoration: underline !important;
  font-weight: 700;
  font-size: 11px;
}

.view_btn:hover {
  color: #1b9fbc;
}

a.join_call {
  color: #1b9fbc;
  font-weight: 700;
  text-decoration: underline !important;
  cursor: pointer;
}

.common_title {
  font-weight: 700;
  text-align: left;
  margin: 0;
  padding: 19px 31px 12px;
  color: #000;
  font-size: 16px;
}

.card-body {
  padding: 0 0 46px 0;
}

table.table tr th {
  padding: 10px;
  text-align: center;
}

table.table tr td:first-child {
  padding: 6px 0 0 15px;
}

table.table tr td {
  padding: 6px 0 0 0;
}

table.table tr td:last-child {
  padding: 6px 15px 0 0;
}

table.table tr th:first-child {
  padding: 10px 10px 10px 21px;
}

.table > thead {
  border-bottom: 1px solid #000 !important;
}

/* ul.blogs_details {
  padding: 12px 15px;
} */

.overflow_scroll {
  overflow-y: scroll;
}

.read_more_btn {
  margin: 0 24px 14px 0;
}

a.light_btn {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.4);
  border-radius: 50px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1b9fbc;
  padding: 15px 32px;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

.blog_card h3 {
  border-bottom: 1px solid #000;
}

.med_card h3 {
  border-bottom: 1px solid #000;
}

.card.med_card {
  height: 225px;
}

.blog_card .card-body {
  padding: 0 0 12px 0;
}

.questionnaire .card-body {
  padding: 7px 15px;
  background: #1b9fbc;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: center;
}

.questionnaire_inner a {
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.02em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assistance .card-body {
  padding: 13px 20px 13px;
  width: 170px;
}

.assistance {
  position: fixed;
  bottom: 0;
  right: 0px;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: 99999;
}

.assistance_box h3 {
  text-align: left;
  font-weight: 700;
  line-height: 20px;
}

.assistance_box p {
  padding: 9px 0 19px 0;
  font-weight: 500;
  line-height: 15px;
  margin: 0;
}

.small_dark_btn {
  background: #1b9fbc;
  border-radius: 5px;
  color: #fff !important;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
}

.assistance_box img {
  margin: 0 0 0 auto;
  display: block;
}

.active_table_row td p {
  background: rgba(27, 117, 188, 0.25);
}

.questionnaire_inner a img {
  margin: 0 0 0 25px;
}

/* Account home / End*/

/* consultation */
.upcoming_con_title p {
  padding: 0 31px 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.upcoming_con_title {
  border-bottom: 1px solid #000;
}

.notes {
  padding: 25px 20px;
  height: 325px;
}

.h-200 {
  height: 200px;
}

.notes p {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.4);
}

.new_consultation {
  padding: 19px 15px 10px 31px;
}

.new_consultation .common_title {
  padding: 0;
}

.new_consultation a.small_dark_btn {
  max-width: 135px;
}

.ques_detail-inner p {
  font-weight: 500;
}

/* consultation / end*/

/* Join Call */
.left_chat {
  max-width: 465px;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 0px 10px 10px 0px;
  padding: 14px 32px;
  width: 100%;
  color: rgba(0, 0, 0, 0.4);
}

.left_chat p {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 700;
}

.right_chat {
  background: #1b9fbc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 0px 10px;
  max-width: 446px;
  margin: 0 0 0 auto;
  padding: 14px 32px;
  width: 100%;
}

.right_chat p {
  color: #fff;
  font-weight: 600;
}

.chat {
  max-height: 200px;
  padding: 20px 0 10px 0;
  height: 60vh;
  overflow-x: hidden !important;
}

.chat_send {
  padding: 20px 32px;
  border-top: 1px solid #000;
}

.live_chat_title h3 {
  border-bottom: 1px solid #000;
}

.chat_send input[type="text"] {
  border: 0;
  font-size: 12px;
}

.chat_send input[type="submit"] {
  background: #1b9fbc;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 0;
  max-width: 130px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 12px;
  height: 35px;
}

/* Join Call ? End*/

/* New consultation */
.new_consultation_booking h2 {
  padding: 30px;
  border-bottom: 1px solid #000;
}

.w-140 {
  width: 165px;
  height: 30px;
}

.date-picker input[type="date"] {
  border: 0;
  border-radius: 5px;
  padding: 4px 13px;
  font-size: 11px;
}

.sub-head {
  padding: 0px 0 10px 25px;
  border-bottom: 1px solid;
  max-width: 600px;
  letter-spacing: -0.02em;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
}

.payment_inner_details {
  padding: 70px;
  height: 100%;
}

.problem_treat h3 {
  font-weight: 700;
  font-size: 29px;
  line-height: 35px;
  color: #1a1f36;
}

.color_grey {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #697386;
}

.quantity select {
  border: 0;
}

.small_parah {
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #000;
  margin: 0;
}

.fees_total {
  max-width: 265px;
  margin: 0 0 0 auto;
  padding: 30px 0 0 0;
}

.fees_total ul li {
  padding: 12px 0;
}

.salec_tax p {
  color: #697386;
  font-weight: 400;
}

.salec_tax {
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}

.payment_method {
  padding: 70px 0;
}

.payment_method img {
  height: 500px;
  margin: 0 auto;
  display: block;
}

.terms {
  margin: 0 18px 0 0;
}

p.line {
  font-size: 28px;
  margin: 0;
  font-weight: 100;
  color: rgba(135, 146, 162, 1);
}

.contact_preferneces .card {
  border: 0;
}

/*  New consultation / end */

/* My account */
.advertisement_info {
  background: #ffffff;
  border: 0.2px solid #ccd2e3;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.ad-title {
  padding: 45px 55px;
}

.ad-title h3 {
  font-weight: 700;
}

.ad-title p {
  font-weight: 700;
  margin: 15px 0 0 0;
  line-height: 20px;
}

.accordian_collapse {
  padding: 12px;
}

.personal_inner_sec {
  padding: 16px 40px 16px 40px;
  background: #fff;
  border-radius: 10px;
  width: 100%;
  border: 0;
}

.update-history {
  margin: 0 0 0 17px;
}

.accordian_collapse .card-header {
  padding: 0;
}

.box_shadow {
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
}

.inner_padding {
  padding: 25px 20px 30px;
}

.dropdown_arrow {
  position: relative;
  text-decoration: none !important;
}

.dropdown_arrow.collapsed::after {
  content: url("../images/icons/right_arrow.svg");
  position: absolute;
  right: 20px;
}

.dropdown_arrow::after {
  content: url("../images/icons/arrow_down.svg");
  right: 20px;
  position: absolute;
}

.accordian_head {
  border-radius: 10px;
}

.accordian_collapse .card-header h5 {
  border-bottom: 0.25px solid #ccd2e3;
  border-radius: 10px;
}

.ml-5 {
  margin: 30px 0 0 60px;
}

.account_info {
  padding: 17px 40px;
}

.account_info::after {
  content: url("../images/icons/right_arrow.svg");
}

.account_info::after {
  content: url("../images/icons/right_arrow.svg");
  position: absolute;
  right: 20px;
  top: 55%;
  transform: translateY(-50%);
}

.account_info h6 {
  width: 30%;
  text-align: left;
}

.account_info p {
  width: 50%;
  text-align: left;
}

/* My account / End */

/* Prescriptions */
.past_prescription td p {
  background: #f5f5f5;
}

.advertisement_info {
  position: absolute;
  right: 0;
  top: 60px;
}

.advertisement_info {
  width: 350px;
}

/* Prescriptions */

/* Bad Breath */
.m-90 {
  margin: 0 0 120px 0;
}

.main_section_title p {
  font-size: 14px;
}

.line_divide {
  width: 100%;
}

.number {
  font-weight: 600;
  font-size: 42px;
  line-height: 100%;
  color: #fff;
  top: 36%;
  left: 32px;
  z-index: 33;
}

.reason-cards p {
  font-size: 14px;
}

.reason-cards {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

section.section-divide {
  padding: 120px 0 0 0;
}

.container-width-custom {
  width: 100%;
  max-width: 80%;
  min-width: 80%;
}

.side-img img {
  border-radius: 8px 0px 0px 8px;
}

.common-pointers li {
  list-style: inherit;
  font-size: 14px;
  color: #000;
  line-height: 27px;
}

.p-185 {
  padding-left: 185px;
}

.duo-buttons .white-btn {
  width: 250px;
}

.ml-80 {
  margin: 0 0 0 80px;
}

.common_issues_inner h3 {
  color: #fff;
}

.p-120 {
  padding: 120px 0 !important;
}

.p-120-60 {
  padding: 120px 0 60px 0 !important;
}

.download_btn {
  width: 250px;
  margin: 36px 0 0 0;
}

.treatment_prevention .reason-cards img {
  border-radius: 10px 10px 0 0;
}

.common_issues_inner {
  display: block;
}

.common_issues_inner img {
  width: 100%;
  border-radius: 8px;
}

/* Bad Breath / End */

/* Jaw problem */
.jawProblem h1 {
  color: #1b75bc;
}

.jawProblem .banner-img {
  background-image: url("../images/banner/4.png");
  background-size: contain;
  background-position: right;
}

/* about us */
.mx-width-700 {
  max-width: 600px;
  margin: 0 auto;
}

.booking_btn {
  max-width: 250px;
  margin: 0 auto;
}

.p-120 {
  padding: 120px 0 !important;
}

.team-img img {
  width: 100%;
}

.aboutUs .banner-img {
  background-image: url("../images/banner/about-us.png") !important;
}

/* about us / end*/

/* footer */
footer {
	background: #2B3674;
}

.footer-content h3 {
	text-align: left;
	margin: 0 0 21px 0;
	color: #fff;
	font-weight: 400;
	font-family: 'Poppins';
}

.footer-content p {
	margin: 0 0 4px 0;
	color: #fff;
	font-weight: 400;
}

.copyright p {
	font-size: 12px;
	color: #fff;
	font-weight: 400;
}

.footer-img a img {
  width: 120px;
}

.footer_btm {
  padding: 28px 0;
}

.footer_upper {
  border-top: 3px solid #000;
}

.footer-content input[type="mail"] {
  border: 0;
  max-width: 600px;
  height: 36px;
  background: #f5f5f5;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 4px 4px 4px 11px;
  font-size: 12px;
  width: 100%;
}

.footer-content input[type="mail"]:focus {
  border: 0;
  outline: 0;
}

.footer-content input[type="mail"]::placeholder {
  color: #000;
  font-weight: 500;
}

.footer-content input[type="submit"] {
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f5f5f5;
  padding: 0 15px;
  font-weight: 500;
  font-size: 12px;
}

.footer-content a:hover {
  color: #000;
  /* font-weight: 600; */
  text-decoration: underline !important;
}

/* footer */

/*admin*/
.auth-full-bg {
  height: 100vh;
}

.auth-full-bg {
  background-color: #1b95bc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.new_logo {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-overlay {
  position: absolute;
  height: 100vh;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  top: 0;
  background-color: #1b95bc;
}

.btn-primary-admin {
  color: #fff;
  background-color: #1b95bc;
  border-color: #1b95bc;
}

.btn-primary-admin:hover {
  color: #fff;
  background-color: #1b95bc;
  border-color: #1b95bc;
}

.bg-grey-admin {
  background-color: #f4f7fe;
}

.blue-heading-admin {
  font-size: 34px;
  font-weight: 600;
  color: #2b3674;
  font-family: "DM Sans", sans-serif;
}

.g-color {
  color: #a3aed0;
  font-family: "DM Sans", sans-serif;
  font-size: 0.8125rem;
}

.auth-full-page-content label {
  margin-bottom: 0.5rem;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #2b3674;
}

.auth-full-page-content .form-control {
  display: block;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 3;
  color: #404040;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e0e5f2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 20px;
  -webkit-transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.blue_text {
  color: #4318ff;
}

.light-green-text {
  color: #1b95bc !important;
}

.check-login label {
  line-height: 5px;
  margin-left: 10px;
}

.password-eye span {
  background: #fff;
  border: 1px solid #e0e5f2;
  border-radius: 15px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px;
}

#password {
  border-right: 0;
}

#eye {
  color: #a3aed0;
  font-size: 17px;
}

#c-eye {
  color: #a3aed0;
  font-size: 17px;
}

#page-topbar {
  position: fixed;
  /* top: -34px; */
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: #fff;
  /* box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .03); */
  width: 280px;
}

.navbar-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  height: 70px;
  padding: 0 calc(24px / 2) 0 0;
}

.navbar-brand-box {
  padding: 0 1.5rem;
  text-align: center;
  width: 280px;
  background: #fff;
}

body[data-sidebar="dark"] .logo-light {
  display: block;
}

.logo-lg img {
  max-width: 140px;
  height: unset;
}

.vertical-menu {
  width: 280px;
  z-index: 1001;
  background: #fff;
  bottom: 0;
  margin-top: 0;
  position: fixed;
  top: 70px;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
}

.h-100 {
  height: 100% !important;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-height-auto-observer-wrapper {
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  -webkit-box-flex: inherit;
  -ms-flex-positive: inherit;
  flex-grow: inherit;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  -webkit-box-sizing: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0 !important;
  bottom: 0;
  right: 0 !important;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  padding: 0 !important;
}

.side-panel-flex-direction {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  align-content: center;
  height: calc(100vh - 90px);
  overflow-y: scroll;
    overflow-x: hidden;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.logout-link {
  text-align: left;
  padding-left: 20px;
}

.metismenu li {
  display: block;
  width: 100%;
}

#sidebar-menu ul li a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 11px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.mm-active .active {
  color: #1b95bc !important;
  border-right: 3px solid #1b95bc;
  border-radius: 3px;
  font-weight: 600;
}

#sidebar-menu ul li a i {
  display: inline-block;
  min-width: 2.15rem;
  padding-bottom: 0.125em;
  font-size: 25px;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

body[data-sidebar="dark"] #sidebar-menu ul li a {
  color: #a6b0cf;
}

#sidebar-menu ul li a {
  color: #a6b0cf;
  font-size: 11px;
  font-family: "DM Sans", sans-serif;
}

body[data-sidebar="dark"] .mm-active .active svg {
  color: #1b95bc !important;
}

.logout-link a {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #a3aed0;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

.main-content {
  margin-left: 280px;
  overflow: hidden;
}

.page-content {
  padding: calc(0px + 24px) calc(24px / 2) 60px calc(24px / 2);
}

.page-title-box {
  padding-bottom: 50px;
}

.cons-top-bar {
  padding-bottom: 0px;
}

.small_text {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
  color: #707eae;
  font-family: "DM Sans", sans-serif;
}

.page-title-box h4 {
  text-transform: capitalize;
  font-weight: 600;
  color: #000;
  font-family: "DM Sans", sans-serif;
  margin-top: 10px;
}

.mb-sm-0.font-size-28 {
  font-size: 22px;
}

form.app-search.d-none.d-lg-block {
  min-width: 350px;
  background: white;
  padding: 10px;
  border-radius: 50px;
}

.app-search .form-control {
  height: 45px;
}

.app-search span {
  line-height: 45px;
  font-size: 18px;
}

.chart_dropdown .dropdown-item {
  font-size: 12px;
}

.w-100 {
  width: 100% !important;
}

img.avata_serch {
  margin-left: 20px;
}

.card-body.h160 {
  height: 240px;
}

.title_text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.02em;
  /* color: #1B95BC; */
  color: #000;
}

.bglight {
  background: #eefdfe;
  border-radius: 16.6227px;
}

.dasb_text {
  text-align: center;
  padding: 12px;
}

.dasb_text h2 {
  font-weight: 500;
  font-size: 25px;
  line-height: 55px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  text-align: center;
  margin: 0px;
  color: #414141;
}

.bg_dasg_ble.bgblue.dasb_text {
  height: 139px;
}

.apexcharts-text tspan {
  font-size: 10px;
}

.dasb_text_p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11.0026px;
  line-height: 14px;
  text-align: center;
  margin-bottom: 10px;
  height: 30px;
}

.dasb_textlastp {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11.2981px;
  line-height: 13px;
  margin: 0px;
  text-align: center;
  color: #9d9fb1;
}

.bgbrown {
  background: #fff0f0;
  border-radius: 16.6227px;
}

.bgblue {
  background: #f3e9ff;
  border-radius: 16.6227px;
}

.bgblue {
  background: #f3e9ff;
  border-radius: 16.6227px;
}

.bgyelow {
  background-color: #fef5ef;
  border-radius: 16.6227px;
}

.dasb_text h1 {
  font-family: "Poppins", sans-serif;
}

.main-content .card-body {
  -webkit-box-flex: 1 !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
  padding: 1.25rem 1.25rem !important;
}

.main-content .card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 20px;
  margin-bottom: 24px !important;
  -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
}

.bg-dash {
  background-color: #f4f7fe;
}

.table th {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #000;
}

.ff-inter tbody.td_color tr td {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000;
  padding: 0.75rem 0.75rem;
}

.dashboard_row {
  background-color: #007aff;
}

.admin-table th {
  border-bottom: 1px solid #eff2f7 !important;
}

.admin-table table.table tr th:first-child {
  padding: 10px;
}

.searcg_icon form.app-search {
  min-width: 250px !important;
  background: white;
  padding: 10px;
  border-radius: 50px;
}

.bg-grey-btn {
  background-color: #5c5c5c !important;
  padding: 5px 15px;
}

.dropdown_custom {
  min-width: 160px;
  padding: 6px 10px;
  color: #414141 !important;
  background: #ffffff;
  border: 1.10818px solid #dbd8fc;
  box-shadow: 0px 110.818px 88.6541px rgba(41, 72, 152, 0.05),
    0px 71.8263px 51.9201px rgba(41, 72, 152, 0.037963),
    0px 42.6853px 28.238px rgba(41, 72, 152, 0.0303704),
    0px 22.1635px 14.4063px rgba(41, 72, 152, 0.025),
    0px 9.02959px 7.22367px rgba(41, 72, 152, 0.0196296),
    0px 2.05218px 3.4887px rgba(41, 72, 152, 0.012037);
  border-radius: 11.0818px;
}

.btn-custom-drop {
  display: inline-block;
  font-weight: 600;
  line-height: 2;
  color: #414141;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.47rem 0.75rem;
  font-size: 0.8125rem;
  border-radius: 15px;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  font-size: 12px;
}

.tot_trd_vech p {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
}

.days_moth .nav-link {
  font-size: 11px !important;
}

.tot_trd_vech h5 {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
}

.active_dealer .td_color tr:hover {
  background-color: #d8edcf !important;
}

.app-search input {
  border-radius: 50px;
  background: #efefef;
}

.app-search input:focus {
  border-radius: 50px;
  background: #efefef;
}

.header-title-text {
  color: #1b95bc;
}

.page-title-box h4 span {
  margin-left: 20px;
  font-size: 20px;
}

.table-defaut-design tbody.td_color tr td {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #000;
  padding: 0.5rem;
}

.h-480 {
  height: 480px;
}

.mx-width .login-from-p {
  width: 440px;
}

/* .td_blue td {
  color: #1B95BC !important;
} */

.active_dealer .td_color tr:hover {
  background-color: #d8edcf !important;
}

/* tr hover */

.table-defaut-design th {
  font-size: 10px !important;
  color: #a3aed0;
}

.table-defaut-design table.table.dt-responsive.dealers_table.nowrap.w-100 a {
  color: #1b95bc;
}

.select-table-bottom-r label {
  color: #000;
  font-size: 12px;
}

.select-page-count-bottom {
  background: transparent;
  box-shadow: none;
  border: none;
}

.blog-img-td {
  max-width: 120px;
  border-radius: 10px;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 5%);
  max-height: 75px;
  object-fit: cover;
  width: 100%;
}

.pending_genr {
  background: #1b95bc;
  /* box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
  border-radius: 50px !important;
  padding: 5px 0px !important;
  text-align: center;
  color: #fff;
  width: 104px;
  font-size: 11px;
  border: 1px solid transparent;
  font-weight: 700;
}

.notes.consult_notes {
  height: 235px;
}

.pending_genr:hover {
  color: #fff !important;
}

.delete-disable {
  background: #c4c4c4;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border-radius: 50px !important;
  padding: 5px 0px !important;
  text-align: center;
  color: #fff;
  margin-left: 20px;
  width: 104px;
  font-size: 11px;
  border: 1px solid transparent;
}

.cre_new {
  background: #1b95bc;
  color: #fff;
  margin-left: 20px;
  border-radius: 10px;
  padding: 8px 16px;
  width: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
}

.cre_new {
  color: #fff !important;
}

/* .form-control-new {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 0.2px solid #EDEFF4 !important;
  border-radius: 10px;
  display: block;
  width: 100%;
  padding: .47rem .75rem;
  font-size: .8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #404040;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  height: 44px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
} */

.form-control-new {
  font-size: 12px;
}

.editorClassName {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border: 0.2px solid #edeff4 !important;
  border-radius: 10px;
  display: block;
  width: 100%;
  padding: 0.47rem 0.75rem;
  min-height: 300px;
}

.editorClassName h1 {
  color: inherit !important;
}

.form-control-new:focus {
  outline: none;
}

.blog-upload-input {
  position: absolute;
  background: #b9b3b3 !important;
  width: 100%;
  height: 40px;
  z-index: 9999;
  opacity: 0;
}

.blog-upload-img {
  font-size: 12px !important;
  padding-top: 10px !important;
  color: #9d9d9d !important;
  position: relative;
  line-height: 18px;
}

.height-texarea-350 {
  height: 350px;
}

.green-btn-medium-c {
  color: #fff !important;
  background-color: #1b95bc !important;
  padding: 0.3rem 1rem !important;
}

.public-DraftStyleDefault-ltr {
  font-size: 11px;
}

#btn {
  position: relative;
  border: none;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  cursor: pointer;
  width: 230px;
}

#btn::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(50% - 0px);
  height: calc(100% - 0px);
  background-color: #1b9fbc;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: 0.3s;
}

.dark-light {
  position: relative;
  padding: 10px;
  color: #000;
  display: inline-block;
  transition: 0.3s;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

#light {
  margin-right: 50px;
}

.dark-light {
  position: relative;
  padding: 10px;
  color: #000;
  display: inline-block;
  transition: 0.3s;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.chat-area-height {
  height: 60vh;
  overflow-x: hidden !important;
  overflow: auto;
}

.placeholder-chat-text {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
}

.send-msg-part {
  display: flex;
  border-top: 1px solid #000;
}

.send-msg-part textarea {
  width: 100%;
  border: none;
  border-top: 1px solid #000;
  padding-top: 10px;
  resize: none;
  outline: none;
  font-size: 11px;
}

.send-msg-part textarea::-webkit-scrollbar {
  display: none;
}

.note-header-part {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title_text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  letter-spacing: -0.02em;
  /* color: #1B95BC; */
  color: #000;
}

.online-status {
  color: #3ac10a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding-left: 12px;
  position: relative;
  padding-top: 2px;
}

.online-status::before {
  content: "";
  width: 5px;
  height: 5px;
  background-color: #3ac10a;
  border-radius: 100px;
  font-size: 20px;
  display: block;
  position: absolute;
  left: 2px;
  top: 9px;
}

.end-chat-btn {
  background: #cacedb;
  padding: 14px;
  height: 0;
  border-radius: 40px;
  font-size: 12px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 0;
  color: #000000;
  text-align: center;
}

.end-chat-btn:hover {
  color: #000000;
}

.left-side-chat {
  width: 100%;
  margin-bottom: 40px;
}

.left-side-chat span {
  width: auto;
  word-break: break-all;
  background: #fff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 0px 10px 10px 0px;
  padding: 8px 10px;
  margin-bottom: 40px;
  color: #000;
  font-size: 10px;
}

.right-side-chat {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}

.right-side-chat span {
  width: auto;
  word-break: break-all;
  background: #1b95bc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px 0px 0px 10px;
  padding: 8px 10px;
  color: #fff;
  font-size: 10px;
}

.send-chat-btn {
  border-radius: 50px;
  box-shadow: 0 4px 14px rgb(0 0 0 / 10%);
  height: 35px;
  margin-top: 8px;
  width: 35px;
  background: #1b95bc;
  color: #fff;
  line-height: 0;
  padding: 10px;
}

.page-title-box h4 {
  text-transform: capitalize;
  font-weight: 600;
  color: #000;
  font-family: "DM Sans", sans-serif;
}

.sub-heading-text-header {
  font-weight: lighter !important;
}

.view-label-input {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px !important;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000 !important;
  padding-left: 0px !important;
}

/* .dropdown-toggle::after {
  display: inline-block;
  margin-left: 5.255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
} */

.input-view-text {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 41px;
  padding: 10px;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #000000;
}

.font-weight500 td {
  font-weight: 500 !important;
}

.small-table-font .blue-link-under {
  color: #2b3674 !important;
  font-weight: 500;
  text-decoration: underline !important;
}

.small-table-font .light-blue-link-under {
  color: #1b9fbc !important;
  font-weight: 500;
  text-decoration: underline !important;
}

.accordion-button {
  font-family: "Poppins";
  background: #fff !important;
  border: none;
  color: #000 !important;
  font-weight: 600;
  font-size: 13px;
}

.collap-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #000000;
}

.select-btn-yes {
  border-radius: 5px;
  background: white;
  font-size: 13px;
  color: #1b95bb !important;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  font-weight: 600 !important;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
  margin: 0px 10px;
}

.adminaccordion .accordion-collapse {
  border: none !important;
}

.mx-width {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 900px;
}

/* userpannel css time----> */
.timecss {
  margin-bottom: 15px;
}

.timecss:hover {
  background-color: #1b9fbc;
  color: #fff;
}

.selected-timecss {
  background-color: #1b9fbc !important;
  color: #fff !important;
}

.hoverYesNo:hover {
  background-color: #1b9fbc;
  color: #fff;
}

.sidebarUser {
  background-color: #1b9fbc !important;
  color: #fff;
}

.sidebarSpan {
  color: #fff !important;
}

/* .account_info::after {
  content: url("../images/icons/right_arrow.svg");
  position: absolute;
  right: 20px;
  top: 55%;
  transform: translateY(-50%);
} */

/* .dropdown_arrow.collapsed::after {
  content: url("../images/icons/right_arrow.svg");
  position: absolute;
  right: 20px;
} */

/* rajinder-----> */

.appointment_modal .re-schedule-btn {
  background: #1b9fbc;
  border-radius: 5px;
  color: #fff !important;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  width: 100%;
  border: 1px solid transparent;
}

.appointment_modal .cancel-btn {
  border-radius: 5px;
  text-align: center;
  letter-spacing: -0.02em;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 12px;
  color: #eb001b;
  line-height: 16px;
  width: 100%;
  border: 1px solid #eb001b;
  margin: 0 0 16px 0;
  text-transform: capitalize;
}

.appointment_modal .modal-content {
  max-width: 240px;
  margin: 0 auto;
  box-shadow: 0px 4px 14px 0px #0000000d;
  border-radius: 10px;
}

.appointment_modal .modal-dialog {
  top: 50%;
  transform: translateY(-50%) !important;
}

.appointment_modal .close-btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0;
}

.appointment_modal button.btn-close {
  opacity: 1;
}

.appointment_modal .cancel-btn:hover {
  background: #eb001b;
  color: #fff;
  text-decoration: none !important;
}

.appointment_modal .re-schedule-btn:hover {
  color: #1b9fbc !important;
  border: 1px solid #1b9fbc;
  background: transparent;
}

.prescription_info p {
  color: #000 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.prescription_info li span {
  color: #000 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

.prescription_popup .card {
  background: #ddefe1;
}

.h-288 {
  height: 221px;
}

.print_btn {
  text-align: center;
  margin: 0 0 32px 0;
}

.print_btn .cre_new {
  border-radius: 5px;
  padding: 5px 16px;
  max-width: 148px;
  width: 100%;
}

.consultation_notes_content .card {
  background: #dde7ef;
}

.h-320 {
  height: 320px;
}

/* .chat_inner {
  height: 200px;
} */

.email-submit-btn {
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f5f5f5;
  padding: 0 20px;
  font-weight: 500;
  font-size: 12px;
}

.blog_detail_page p {
  color: #515365;
  font-size: 14px;
}

.h-360 {
  height: 360px;
}

.blog_detail_page .media-body h3 {
  text-align: left;
}

ul.blog_listing {
  padding: 0;
}

ul.blog_listing li b {
  font-size: 15px;
}

.card-img-top {
  border-radius: 10px;
}

.chat_table th {
  min-width: 100px !important;
}

.chat_btn {
  max-width: 240px;
  width: 100%;
}

.new_pres .form-group input,
.new_pres .form-group textarea {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px !important;
  color: #000;
  font-weight: 500;
}

.new_pres .form-group input::placeholder {
  /* color: #000; */
  font-weight: 400;
}

.prescripion_notes textarea.form-control {
  min-height: 109px;
}

.create_new_btn {
  display: flex;
  justify-content: flex-end;
}

.create_new_btn .cre_new {
  border-radius: 7px;
  width: 125px;
}

.previous_pres .card {
  height: 100%;
}

.previous_pres {
  height: 100%;
}

.back_button {
  width: 37px;
  height: 37px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub_head h4 {
  font-size: 18px;
  letter-spacing: -0.02em;
  color: #000000;
  font-weight: 500 !important;
}

.w-400 {
  max-width: 430px;
  width: 100%;
}

.prescription_info p {
  color: #000 !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.prescription_info li span {
  color: #000 !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
}

.prescription_popup .card {
  background: #ddefe1;
}

.h-288 {
  height: 221px;
}

.print_btn {
  text-align: center;
  margin: 0 0 32px 0;
}

.print_btn .cre_new {
  border-radius: 5px;
  padding: 5px 16px;
  max-width: 148px;
  width: 100%;
}

.start_call {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.consulatation_card {
  position: relative;
}

.start_call .cre_new {
  margin: 0;
  border-radius: 10px;
  width: 150px;
  border: 0;
  font-size: 11px;
}

.blue_btn {
  border: 0;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  width: 150px;
  padding: 8px;
  height: 40px;
}

.chat_buttons .white-btn {
  border-radius: 10px;
  width: 150px;
  margin: 0;
  height: 40px;
  margin: 0 0 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  font-size: 12px !important;
}

.consultationNotes .doctorNotes .notes p {
  color: #000;
}

span.name_color {
  color: #1b95bc;
  line-height: 34px;
  margin: 0 !important;
}

.overflow_table tbody {
  display: block;
  max-height: 156px;
  overflow-y: auto;
  table-layout: fixed;
}

.overflow_table tbody tr thead {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.overflow_table thead {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
}

.overflow_table tr {
  display: table !important;
  table-layout: fixed !important;
  width: 100% !important;
}

.overflow_table td p {
  height: 40px;
}

.next_page_btn-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.height_scroll {
  overflow-y: auto;
  max-height: 300px;
  overflow-x: hidden;
}

.consultation_table table.table tr td:first-child {
  width: 25%;
}

.consultation_table table.table tr td:nth-child(2) {
  width: 15%;
}

.consultation_table table.table tr td:nth-child(3) {
  width: 30%;
}

.consultation_table table.table tr td:last-child {
  width: 30%;
}

.consultation_table table.table tr th:first-child {
  width: 25%;
}

.consultation_table table.table tr th:nth-child(2) {
  width: 15%;
}

.consultation_table table.table tr th:nth-child(3) {
  width: 30%;
}

.consultation_table table.table tr th:last-child {
  width: 30%;
}

.prescription_table table.table tr td:first-child {
  width: 22%;
}

.prescription_table table.table tr td:nth-child(2) {
  width: 38%;
}

.prescription_table table.table tr td:nth-child(3) {
  width: 20%;
}

.prescription_table table.table tr td:last-child {
  width: 20%;
}

.prescription_table table.table tr th:first-child {
  width: 22%;
}

.prescription_table table.table tr th:nth-child(2) {
  width: 38%;
}

.prescription_table table.table tr th:nth-child(3) {
  width: 20%;
}

.prescription_table table.table tr th:last-child {
  width: 20%;
}

.notes_input textarea.form-control {
  min-height: 100%;
  background: transparent;
  padding: 0;
  resize: none;
}

.cons_notes_height {
  height: 172px !important;
}

.notes {
  padding: 25px 20px;
  height: 292px;
}

.join_call_notes .card-body {
  padding: 0 0 16px 0;
}

.join_call_notes {
  height: 587px;
}

.join_call_notes .consulatation_card {
  height: 289px;
}

.printBtn {
  max-width: 136px;
  margin: 25px auto 0;
}

.formik-errors {
  color: red;
  font-size: 11px;
}

.output-view {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 13px;
  font-weight: 600;
}

.add-cirle-p {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.add-cirle-p span {
  background: #1b9fbc;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 18px;
}

.colorcc {
  border-radius: 5px;
  background-color: #1b9fbc;
  font-size: 11px;
  color: #fff;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 9px 22px;
  text-align: center;
  font-weight: 600 !important;
  max-width: 140px;
  width: 100%;
  transition: 0.3s;
}

.selected_color {
  background-color: #1b9fbc !important;
  color: #fff !important;
}

.right-chat-fixed {
  width: 320px;
  position: fixed;
  right: 20px;
  z-index: 999;
  bottom: 0;
}

.assist_cross_button {
  cursor: pointer;
}

/* Falak */

.hide_chat {
  display: none;
}

.upConsNotes {
  background: #ffffff;
  padding: 0;
  height: 100% !important;
}

.viewPresHome {
  position: absolute;
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

/* .font-black td {
  color: #000
} */

/* 04-01-2023 */

/* .doctor-video {
  z-index: 9999;
  position: absolute;
  right: 4px;
  bottom: 3px;
  width: 190px;
  border-radius: 10px;
  height: 94px;
} */
.full-video {
  aspect-ratio: unset;
  object-fit: cover;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 1;
  position: relative;
  height: 303px;
}

.main-content .video_call_section {
  border: 0 !important;
  box-shadow: unset !important;
}

video.doctor-video {
  width: 229px;
  position: absolute;
  right: 2px;
  bottom: 1px;
  border-radius: 10px;
  z-index: 2;
  height: 106px;
  object-fit: cover;
}

.card.video_call_section {
  border: 0 !important;
  box-shadow: unset !important;
  line-height: 0;
}

.video_call_section .consulatation_card .large_img {
  border-radius: 10px;
  width: 100% !important;
}

.video_call_frame {
  position: absolute;
  right: 1px;
  bottom: 1px;
  width: 229px;
  height: 106px;
}

/* .card.video_call_section .consulatation_card {
  position: relative;
  height: 323px;
} */

/* Sonakshi css start */

.subques {
  color: #000;
  font-weight: 500;
  margin-bottom: 10px;
}

.sub-question-show {
  margin-top: 20px;
  margin-bottom: 20px;
  /* border-bottom: 1px solid #e9e9e9; */
  padding-bottom: 20px;
}

.focus-input:focus {
  background: #efefef;
  border: unset;
  padding: 14px 16px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 15px;
  color: #808080;
}

.small-save {
  width: 90px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.save-fix-bottom {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  border-top: 1px solid #e9e9e9;
}

.minimize {
  background: #1b95bc;
  border: none;
  color: #ffffff;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 22px;
  height: 22px;
  font-size: 19px;
  line-height: 21px;
  border-radius: 50%;
}

.medHisColor {
  background-color: #1b9fbc;
  color: #fff !important;
}

.according-colum-data {
  margin: 0px !important;
  padding: 0px !important;
}

.according-colum-data li {
  margin: 0px 0px 10px 0px !important;
  width: 100%;
}

.blue-text-box {
  border-radius: 5px;
  background: #fff;
  font-size: 11px;
  color: #1b95bb;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 12px 10px;
  text-align: left;
  font-weight: 600 !important;
  width: 100%;
}

.add-more-btn button {
  background: #1b9fbc;
  border: none;
  outline: none;
  color: #fff;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 3px;
}

.add-more-btn {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.height-330 {
  height: 265px;
}

.z-index1 {
  position: relative;
  z-index: 9999;
}

.table_fix_height {
  width: 100%;
  overflow: auto;
  height: 200px;
  display: table-caption;
}

.common_table td {
  min-width: 150px !important;
}

.common_table th {
  min-width: 125px;
}

.overflow-x-scroll {
  overflow: auto;
}

.closeBtn {
  position: absolute;
  right: 4px;
  font-size: 23px;
  top: 0px;
}

.height45 {
  height: 45px;
}

.consult_pres_bg .consulatation_card {
  background: #dde7ef;
  border-radius: 10px;
}

.fc .fc-col-header-cell-cushion {
  color: #555555 !important;
  font-size: 10px;
}

.fc .fc-daygrid-day-top {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.02em;
}

.fc .fc-daygrid-day-number {
  color: #555555;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}

a.fc-daygrid-event {
  background: rgba(27, 159, 188, 0.5);
  color: #555555;
  font-size: 10px;
  list-style: none !important;
  letter-spacing: -0.02em;
  font-weight: 400 !important;
  display: flex;
  justify-content: space-between;
  padding: 2px 12px;
  flex-direction: row-reverse;
  border-radius: 0;
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 400;
}

.fc-daygrid-event-dot {
  display: none;
}

.fc-event-time {
  width: 25%;
  display: flex;
  justify-content: flex-end;
}

.fc-event-title {
  width: 70%;
}

.show-img-profile {
  width: 100px;
  height: 100px;
  object-fit: cover;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.show-img-profile p {
  font-size: 16px;
  margin: 0px;
}

.img-upload-input {
  background: #ede9e9;
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  transform: translate(-50%);
  opacity: 0;
  cursor: pointer;
  top: 0px;
  border-radius: 50px;
}

.history_input_field {
  border-radius: 5px;
  background: #fff;
  font-size: 12px;
  color: #1b95bb;
  border: 0;
  display: inline-block;
  padding: 13px 7px;
  font-weight: 600 !important;
  line-height: 20px !important;
  max-width: unset !important;
  text-align: left !important;
}

section.main_content_wrapper .col-lg-10 {
  background: #f5f5f5;
  height: auto;
  min-height: 100vh;
}

.read_more_btn a.light_btn {
  cursor: pointer;
  transition: 0.3s;
}

.read_more_btn a.light_btn:hover {
  background: #1b9fbc;
  color: #fff !important;
}

.name_cursor {
  cursor: pointer;
}

.overflow_table.pres_table tbody {
  max-height: 425px !important;
  overflow: auto;
}

button.end-chat-btn {
  font-size: 11px;
}

.blog_detail_page ul li {
  font-size: 11px;
}

.blog_detail_page img.card-img-top {
  height: 600px;
  object-fit: cover;
}

.notes_height .notes {
  height: 266px;
}

.chat_text {
  width: 100%;
  border: none;
  padding-top: 10px;
  resize: none;
  outline: none;
  font-size: 11px;
  background: transparent;
}

.right-chat-fixed .title_text {
  margin: 0;
  line-height: 12px;
}

.chat-box-c .title_text {
  line-height: 13px;
}

.chat-box-c .note-header-part {
  align-items: flex-start;
}

.minimize_chat {
  position: fixed;
  right: 20px;
  bottom: 0;
  width: 320px;
}

.minimize_chat .note-header-part {
  justify-content: flex-start;
}

.minimize_chat .online-status {
  padding-left: 21px;
}

.minimize_chat .online-status::before {
  left: 12px;
}

.maximize_img img {
  max-width: 15px;
  width: 100%;
  cursor: pointer;
}

.action_btn a {
  height: 28px;
}

.border_btn {
  border: 1px solid #eff0f1;
  border-radius: 50px !important;
  padding: 5px 16px !important;
  text-align: center;
  color: #fff;
  width: 104px;
  font-size: 11px;
  color: #1b95bc;
  background: transparent;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.form_control_new {
  background: #f5f5f5;
  max-width: 126px;
  max-height: 26px;
  width: 100%;
  height: 100%;
  padding: 3px 20px;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #a3aed0;
  font-weight: 500;
  appearance: auto;
  border-radius: 5px;
  border: 0;
}

.closed_time {
  background: #cacedb;
}

.open_time {
  background: #f5f5f5;
}

button.plus_btn {
  background: #1b9fbc;
  border: 0;
  width: 37px;
  height: 37px;
  border-radius: 10px;
}

button.calendar_btn {
  border: 0;
  background: #1b9fbc;
  border-radius: 10px;
  color: #fff;
  padding: 3px 20px;
  font-size: 12px;
  position: absolute;
  left: 50%;
  line-height: 28px;
  font-weight: 600;
  transform: translateX(-37%);
}

button.calendar_btn img {
  margin: 0 0 0 30px;
}

button.fc-today-button.fc-button.fc-button-primary {
  background: #1b9fbc;
  border: 0;
}

.medical_accordian div#accordionExample .accordion-item {
  border: 0.2px solid #ccd2e3;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 5%);
  border-radius: 10px;
}

.medical_accordian
  div#accordionExample
  .accordion-item:first-of-type
  .accordion-button {
  border-radius: 10px;
  border-bottom: 0.2px solid #ccd2e3;
}

.medical_accordian
  div#accordionExample
  button.accordion-button.fw-medium.collapsed {
  border-bottom: 0 !important;
}

.medical_accordian .accordion-body {
  padding: 1.5rem 1.25rem;
}

.medical_accordian .accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 10px;
}

h4.title_text {
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  /* margin: 0 0 37px 0; */
}

/* .symptom-img img {
  width: 100%;
  max-width: 240px;
} */

/* .symptom-img {
  width: 32%;
} */

.inner_prblm_detail.inner-prblm_img_end {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.questionnaire_view .collap-text {
  font-size: 12px;
}

.questionnaire_view .select-btn-yes {
  padding: 8px 13px;
  margin: 0px 5px;
  font-size: 10px;
}

.questionnaire_view {
  height: 554px;
  overflow: auto;
}

.ques_view_input_field {
  padding: 6px 7px;
  margin: 6px 0 0 0;
  min-height: 34px;
  max-height: 70px;
  overflow: auto;
}

.questionnaire_view .subques {
  font-size: 11px;
  margin: 0 0 2px 0;
}

.calendar_update_button {
  background: #1b95bc;
  max-width: 135px;
  width: 100%;
}

.react-datepicker__input-container input {
  border-radius: 5px;
  border: 0;
  padding: 3px 6px;
  font-weight: 500;
}

.closed_time .react-datepicker__input-container input {
  background: #cacedb;
}

.open_time .react-datepicker__input-container input {
  background: #f5f5f5;
}

.trans_table_fix_height {
  width: 100%;
  overflow: auto;
}

.disabled-time {
  pointer-events: all !important;
  color: #0000005c !important;
  cursor: not-allowed;
}

.disabled-time:hover {
  background: #fff;
}

/* Sonakshi css end */

.option-btn-border {
  border: 1px solid transparent;
}

.border-focus {
  border: 1px solid #1b95bc;
}

/* Diagnosis */

.diagnosis-types h3 {
  text-align: left;
  margin: 0 0 20px 0;
}

.diagnosis-types ul li {
  padding: 7px 0;
  list-style: initial;
}

.diagnosis-types {
  padding: 20px;
}

.diagnosis-types ul li span {
  font-weight: 600;
  margin: 0 5px 0 0;
}

.text-style-decoration li {
  list-style: inherit !important;
}

.extra_head {
  margin: 16px 0 6px 0;
  font-size: 17px;
}

.imgs_section {
  display: flex;
}

.imgs_section li {
  list-style: none !important;
}

.ml-30 {
  margin: 0 0 0 45px;
}

.box_text {
  border: 1px solid #000;
  padding: 12px;
  max-width: 280px;
  margin: 20px 0 0 0;
  border-radius: 5px;
}

.fw-700 {
  font-weight: 500;
  font-size: 14px;
}

.list-style-none {
  list-style: none !important;
}

.text-style-decoration {
  margin: 0;
}

.tooth_prblms ul {
  margin: 0;
}

.diagnosis_inner.mb-5 {
  margin: 0 0 110px 0 !important;
}

/* .card_shadow_height{
  height: 580px;
}
.m-66{
  margin: 75px 0 0 0;
}
.cursor_pointer a{
  cursor: pointer;
} */

.r-btn {
  font-size: 14px !important;
  padding: 14px;
  width: 180px;
}

button.fc-next-button.fc-button.fc-button-primary {
  background: #1b9fbc;
  border: 0;
}

button.fc-prev-button.fc-button.fc-button-primary {
  background: #1b9fbc;
  border: 0;
}

.fc .fc-button:focus,
.fc .fc-button-primary:focus {
  box-shadow: none;
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background: #1b9fbc;
  opacity: 0.65;
}

.calendar_date input[type="date"] {
  border: 0;
  background: #e0e3ee;
  padding: 2px 7px;
  border-radius: 5px;
}

.blog_btns {
  width: 330px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h-337 {
  height: 337px;
}

.main-content .call_section .padding0 {
  padding: 0 !important;
}

.h-700 .card {
  height: 642px;
}

.h-100vh {
  height: 100vh;
}

.leave-call-btn:disabled {
  background-color: #fff;
  border-color: #fff;
}

.h-20 {
  height: 20%;
}

.pres_width {
  width: 450px;
  position: absolute;
  top: 20px;
  right: 16px;
}

.top0 {
  top: 0;
}

.card_shadow_height {
  height: 580px;
}

.m-66 {
  margin: 75px 0 0 0;
}

.cursor_pointer a {
  cursor: pointer;
}

.visibility-hidden {
  visibility: hidden;
}

/*27/1/2023 sumit*/
.active-btn {
  background: #1b95bb;
  color: #fff;
}

.symptom_img_depiction img {
  max-width: 200px;
  width: 100%;
  display: block;
  height: 100px;
  object-fit: cover;
}

.symptom_img_depiction {
  margin: 0 0 0 7px;
}

.w-75 {
  width: 88px !important;
}

.select_option_btn {
  border-radius: 5px;
  background: #fff;
  font-size: 10px;
  color: #1b95bb;
  border: 0;
  line-height: 100% !important;
  display: inline-block;
  padding: 9px 6px;
  text-align: center;
  font-weight: 600 !important;
  max-width: 140px;
  width: 100%;
  transition: 0.3s;
}

.btn_end {
  display: block;
  margin: 0 0 0 auto;
}

.active-btn {
  background: #1b95bb;
  color: #fff;
}

ul.pagination li {
  color: #fff;
}

.pagination {
  display: flex;
  list-style: none;
  max-width: 200px;
  justify-content: space-between;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #ddd;
}

.pagination_inner {
  margin: 0 auto;
  width: 250px;
  display: block !important;
}

li.previous.disabled,
li.next.disabled {
  background: transparent;
  box-shadow: none;
  padding: 5px 10px;
}

li.selected a {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  background: #1b95bc;
  color: #fff !important;
  font-weight: 600;
  border-radius: 4px;
}

ul.pagination li {
  color: #1b95bc;
}

li.next a,
li.previous a {
  color: #1b94ba;
  font-size: 20px;
  font-weight: 400;
}

li.previous.disabled a,
li.next.disabled a {
  color: #a2a9bf !important;
  font-size: 20px;
  font-weight: 400;
}

.pagination_inner {
  margin: 0 auto;
  width: 200px;
  display: block !important;
}

.custom-table-new thead {
  vertical-align: bottom;
  width: 91vw;
}

.switches-container {
  width: 16rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: white;
  line-height: 2.1rem;
  border-radius: 3rem;
  margin: 0 auto 0 100px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;
  color: black !important;
  position: relative;
  z-index: 9;
  font-family: "DM Sans";
}

.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;

  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}

.switch {
  border-radius: 3rem;
  background: #1b9fbc;
  height: 100%;
}

/*sumit falak system css*/
/* .dealer-table-c,
tbody {
  display: block;
  max-height: 670px;
  overflow-y: auto;
  table-layout: fixed;
} 

.dealer-table-c,
tbody tr,
thead {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.dealer-table-c {
  overflow-y: hidden !important;
  table-layout: fixed !important;
  display: block !important;
}

.dealer-table-c,
tbody tr,
thead {
  display: table;
  table-layout: fixed;
  width: 100%;
}

*/

.dealer-table-c table {
  width: 100%;
}

.dealer-table-c thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.dealer-table-c tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.dealer-table-c tbody {
  /* display: block; */
  overflow-y: auto;
  table-layout: fixed;
  max-height: 400px;
  height: 400px;
}

.dealer-table-c td {
  width: 150px;
}

.special_days_table table {
  width: 100%;
}

/* .special_days_table thead {
  display: table;
  width: 100%;
  table-layout: fixed;
} */

.special_days_table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

/* .special_days_table tbody {
  display: block;
  overflow: auto;
  table-layout: fixed;
  max-height: 200px;
  height: 200px;
} */

.special_days_table td {
  width: 150px;
}

.chat_table-height table {
  width: 100%;
}

.chat_table-height thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.chat_table-height tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.chat_table-height tbody {
  display: block;
  overflow-y: auto;
  table-layout: fixed;
  max-height: 330px;
  height: 330px;
  overflow-x: hidden;
}

.chat_table-height td {
  width: 150px;
}

.transaction-table-height table {
  width: 100%;
}

.transaction-table-height thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.transaction-table-height tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.transaction-table-height tbody {
  display: block;
  overflow-y: auto;
  table-layout: fixed;
  max-height: 347px;
  height: 347px;
}

.transaction-table-height td {
  width: 150px;
}

.select-table-bottom-r {
  margin-top: 50px;
}

li.previous {
  padding: 0px 10px !important;
}

li.previous {
  padding: 5px 10px !important;
}

.next {
  background: transparent;
  box-shadow: none;
  padding: 5px 10px !important;
}

.live_chat_table_fix_height {
  height: 300px;
  /* display: table-caption; */
  width: 100%;
  overflow: auto;
}

.delete_member_modal .modal-body h4 {
  font-weight: 500;
  max-width: 300px;
  margin: 0 auto;
}

/* css */
.blog_title h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 35px;
}

.blog_detail_section {
  margin-bottom: 40px;
}

.blog_detail_section .blog_inner_pannel {
  background-color: #ffffff;
  border-radius: 30px;
  padding: 45px 60px;
  margin-top: 80px;
  position: relative;
  box-shadow: 0 8px 30px #c0bad76b;
}

.blog_detail_section .blog_inner_pannel .review {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.blog_detail_section .blog_inner_pannel .section_title {
  margin-bottom: 33px;
}

.blog_detail_section .blog_inner_pannel .section_title h2 {
  margin-bottom: 4px;
}

.blog_detail_section .blog_inner_pannel .review span:first-child {
  color: #1b9fbc;
  display: inline-block;
  font-weight: 700;
}

.blog_detail_section .blog_inner_pannel .review span:last-child {
  color: #1b9fbc;
  display: inline-block;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #1b9fbc;
}

.section_title h2 {
  line-height: 40px;
  font-size: 32px;
  font-weight: 600;
  color: #000000;
}

.blog_detail_section .blog_inner_pannel .main_img {
  margin-bottom: 30px;
}

.blog_detail_section .blog_inner_pannel .main_img img {
  width: 100%;
  border-radius: 15px;
  height: 600px;
  object-fit: cover;
}

.blog_detail_section .blog_inner_pannel .info ul {
  padding-left: 50px;
  padding-top: 10px;
  margin-bottom: 30px;
}

.blog_detail_section .blog_inner_pannel .info ul li p {
  position: relative;
  /* padding-left: 25px; */
  margin-bottom: 0;
}

.info ul li {
  list-style: disc;
}

.blog_detail_section .blog_inner_pannel .info ul li p .icon {
  position: absolute;
  left: 0;
  color: #1b9fbc;
}

.blog_detail_section .blog_inner_pannel .two_img {
  margin-bottom: 70px;
}

.blog_detail_section .blog_inner_pannel .info h3 {
  font-weight: 500;
  color: #1b9fbc;
  margin-top: 41px;
  font-size: 30px;
  margin-bottom: 34px;
}

.blog_detail_section .blog_inner_pannel .quote_block {
  background-color: #f6f4fe;
  border-radius: 12px;
  padding: 55px 35px 35px 60px;
  position: relative;
  margin-top: 50px;
  margin-bottom: 35px;
}

.blog_detail_section .blog_inner_pannel .quote_block .q_icon {
  position: absolute;
  top: -20px;
  width: 63px;
  height: 63px;
  background-color: var(--bg-white);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_detail_section .blog_inner_pannel .quote_block h2 {
  font-size: 25px;
  line-height: 37px;
}

.blog_detail_section .blog_inner_pannel .quote_block p {
  margin-bottom: 0;
  margin-top: 15px;
}

.blog_detail_section .blog_inner_pannel .quote_block .name {
  color: #1b9fbc;
  font-weight: 700;
}

.blog_detail_section .blog_inner_pannel .quote_block .q_icon img {
  width: 35px;
}

.blog_detail_section .blog_inner_pannel .blog_authore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  border-top: 1px solid #d6d3e3;
  border-bottom: 1px solid #d6d3e3;
  margin: 35px 0;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1b9fbc;
  margin-bottom: 5px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text span {
  font-size: 14px;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul {
  display: flex;
}

.blog_detail_section .blog_inner_pannel .blog_authore .social_media ul li a {
  display: block;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 37px;
  font-size: 15px;
  border: 1px solid #ccc9d7;
  border-radius: 50px;
  margin-right: 10px;
  transition: 0.4s all;
}

.blog_detail_section .blog_inner_pannel .blog_tags ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blog_detail_section .blog_inner_pannel .blog_tags ul .tags p {
  margin-bottom: 0;
  color: #1b9fbc;
  font-weight: 500;
  margin-right: 5px;
}

.blog_detail_page .blog-content-html li {
  list-style: disc;
}

.blog_detail_section .blog_inner_pannel .blog_authore .authore_info .text {
  margin: 0 0 0 15px;
}

ul.blogs_details {
  padding: 12px 15px;
  height: 284px;
}

ul.blogs_details li {
  position: relative;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: 100%;
  margin: 0 14px;
}

ul.blogs_details li img {
  border-radius: 10px;
  object-fit: cover;
  height: 260px;
  width: 460px;
}

.blog_inner {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}

.blog_title {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  max-width: 175px;
  height: 100px;
  padding: 21px 22px 14px;
  width: 100%;
}

.blog_content_page ul.blogs_details li {
  width: 48%;
  margin: 18px 0;
}

.blog_content_page ul.blogs_details {
  justify-content: space-between !important;
  flex-wrap: wrap;
}

.blog_content_page ul.blogs_details li img {
  border-radius: 10px;
  object-fit: cover;
  height: 260px;
  width: 100%;
}

.blog_content_page ul.blogs_details {
  height: unset;
}

.blog_banner .banner-content {
  padding: 120px 0;
}

.blog_banner {
  background: #1b95bc12;
}

section.blog_banner h1 {
  font-weight: 500;
  color: #000000 !important;
}

.deleteBtn {
  border: 0;
  background: transparent;
}

.deleteBtn i {
  font-size: 20px;
  color: #a6b0cf;
}

.submit_btn {
  max-width: 150px;
  border-radius: 10px;
}

.btn_submit {
  text-align: flex-end;
}

.create_admin {
  box-shadow: unset !important;
  border: 0;
}

.create_admin .card-body {
  padding: 0;
}

.create_admin .form-control {
  padding: 14px 16px;
  border-radius: 6px;
  font-size: 11px;
  line-height: 15px;
  color: #808080;
  border: 1px solid #fbe9e9;
  background: transparent;
}

.submit_btn {
  border-radius: 10px !important;
}

.delete_member_modal .modal-body h4 {
  font-size: 17px;
  line-height: 24px;
}

.delete_member_modal .modal-body {
  padding: 38px;
}

.delete_member_modal .modal-footer {
  display: flex;
  justify-content: space-between;
}

.ques_detail {
  margin: 5px 0;
}

.options_part.questions_options ul {
  margin: 0;
}

.btn_end {
  font-size: 14px !important;
  display: block;
  margin: 0 0 0 auto;
  padding: 12px;
}

.result_ready {
  margin: 35px 0 0 0;
}

.btn-more-info {
  font-size: 13px;
  line-height: 20px;
}

.book-consultation {
  font-size: 14px;
}

.jaw_ques_detail-inner {
  flex-direction: column;
  padding: 30px 20px;
}

.jaw_questions_options {
  margin: 15px 0 0 0;
}

.jaw_symptom_ul {
  flex-wrap: wrap;
  justify-content: center;
}

.jaw_select_option_btn {
  width: 100% !important;
  max-width: unset;
  font-size: 11px;
  line-height: 14px !important;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jaw_symptom_li {
  width: 31%;
  margin: 10px 9px !important;
}

.jaw_white-btn {
  margin: 49px 0 0;
}

.dropdown_white.dropdown .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font-size: 14px;
}

.dropdown_white.dropdown .btn-success {
  color: #1b95bc;
  background-color: #ffffff;
  padding: 8px 14px;
  border-radius: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  width: 130px;
}

.tabs_margin .blue_btn {
  margin: 0 10px 0 0;
}

.tabs_margin.chat_buttons .white-btn {
  margin: 0 10px 0 0;
}

.dropdown_white.dropdown button#dropdown-basic {
  border: 0;
  height: 40px;
}

.dropdown_white .dropdown-menu.show {
  width: 100%;
}

.dropdown_white .btn-success.dropdown-toggle:focus {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%) !important;
}

section.main_content_wrapper {
  height: 100vh;
  padding: 70px 0 0 0;
}

.header_inner {
  padding: 14px 17px;
}

.medi_details {
  height: 291px;
}

.main_header {
  position: fixed !important;
  width: 100%;
  left: 0;
  top: 0;
}

.logo_width img {
  max-width: 200px;
  width: 100%;
}

.sticky-blue-header {
  background: #fff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.m-120 {
  margin: 120px 0 0 0;
}

.sticky-header {
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
}

.sticky-header .nav-item .nav-link {
  color: #000 !important;
}

.blackLogo {
  display: none;
}

.sticky-header .blackLogo {
  display: block;
}

.sticky-header .whiteLogo {
  display: none;
}

.delete_admin_modal .modal-content {
  max-width: 500px;
  margin: 0 auto;
}

.delete_admin_modal .modal-body {
  padding: 50px 20px;
}

.delete_admin_modal .modal-footer {
  justify-content: center;
}

.delete_admin_modal .btn-custom {
  font-size: 14px;
  line-height: 19px;
}

.delete_admin_modal .modal-body h4 {
  max-width: 280px;
  margin: 0 auto;
  font-weight: 600;
  line-height: 22px;
}

.admin-popup .modal-content {
  max-width: 500px;
  margin: 0 auto;
}

.admin-popup .modal-footer .confirmation_btn {
  padding: 11px 26px;
  font-size: 14px;
  line-height: 19px;
}

.admin-popup .form-control:focus {
  background-color: #efefef;
}

.full-calendar .fc-toolbar-chunk {
  width: calc(100% - 130px);
}

.full-calendar .fc-toolbar-chunk h2 {
  text-align: center;
}

.chat_table_height {
  height: 399px;
}

.no_content_table {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 500;
  font-size: 15px;
}

.h-393 {
  height: 393px;
}

.notes_btn button {
  width: 120px !important;
}

.chat_buttons {
  margin: 0 0 26px 0;
}

.h-250 {
  height: 293px;
}

.h-370 {
  height: 380px;
}

.h-150 {
  height: 200px;
}

.no_history_table {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}

.right-side-chat.admin-text-right {
  margin: 0 0 12px auto;
  max-width: 270px;
}

.left-side-chat.admin-text-left {
  margin: 0 auto 12px 0;
  display: flex;
  max-width: 270px;
}

.admin-text-left.left-side-chat span {
  margin-bottom: 0px;
}

.long-text-btn {
  font-size: 14px;
  line-height: 20px;
}

.dentailpain_result_ready {
  padding: 0 0 40px 0;
}

.h-185 {
  height: 185px;
}

.h-228 {
  height: 228px;
  overflow: auto;
}

.medication_subhead {
  padding: 0 0 10px 0;
  border-bottom: 1px solid #000;
}

.update_btn {
  border: 0;
  margin: 20px 0 0 0;
}

.user-blog-img {
  width: 100%;
}

.med-td p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  margin: 0 !important;
}

table.admin-prescription tr th {
  padding: 10px 2px;
}

.vehicles_table td {
  padding: 10px 8px !important;
}

.admin-table table.table tr th:first-child {
  padding: 10px 8px !important;
}

.causes-detail {
  padding: 0 0 0 20px;
}

.causes-detail li {
  list-style: disc;
}

.disease_detail_card.reason-cards p {
  font-size: 14px;
  height: 150px;
}

.causes_inner_detail {
  height: 245px;
}

.causes_box_shadow {
  padding: 35px;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 25%);
  border-radius: 11px;
}

.treatment_detail ul li {
  margin: 18px 0;
}

.treatment_detail ul li span {
  font-weight: 600;
}

.treatment_detail ul li:last-child {
  margin: 18px 0 0 0;
}

.m-60 {
  margin: 0 0 60px 0;
}

.treatment-prevention p {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.24px;
}

.treatment-prevention li {
  margin: 12px 0;
}

.treatment-prevention ul {
  margin: 0 0 38px 0;
}

.common_issues_detailPage h1 {
  font-size: 32px;
}

.common_issues_detailPage .banner-content p {
  font-size: 14px;
}

.single_time li {
  background: #fff;
  border-radius: 5px;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 6px;
  padding: 8px 15px;
  text-align: center;
  width: 13%;
}

.single_time li {
  /* width: 13%; */
  text-align: center;
  font-weight: 500;
  color: #000;
}

.ulcer_img_logged_in {
  margin: 0 0 12px;
}

.h-400 {
  height: 400px !important;
}

.spinnerDiv {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  place-content: center;
}

.spinnerDiv .text-primary {
  color: #1b95bc !important;
}

.spinnerClass {
  font-size: xx-large;
  font-weight: 900;
}

.spinner-border {
  width: 10rem;
  height: 10rem;
}

.ulcersImgDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.inner_points li {
  list-style: none !important;
}

.select_input {
  background: #fff !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  padding: 10px !important;
}

.prblm_img img {
  width: 100%;
}

.date_width {
  width: 178px !important;
}

.banner-img h2 {
  color: #fff;
}

.accordian_btn {
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #000000;
}

.booking_date_width {
  width: 256px;
  color: #000;
  font-size: 14px;
  font-weight: 400 !important;
  padding: 7px;
  max-width: unset;
}

.text_link {
  color: #1b9fbc;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  max-width: 400px;
  text-align: center;
  text-decoration: underline !important;
}

.text_link:hover {
  color: #1b9fbc;
}

.symptom-img img {
  width: 100%;
}

.same-width-btn .option-btn {
  height: 90px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 15px !important;
  line-height: 16px !important;
  justify-content: center;
}

.prblm_img .img_text {
  cursor: pointer;
}

.w-88 {
  width: 88px !important;
}

.options_part .select_option_btn:hover {
  background: #1b9fbc;
  color: #fff !important;
}

.fixHeight {
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deletChat-btn {
  background: #f5f5f5;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: -0.02em;
  color: #000000;
  font-weight: 700;
}

.fix_input_height {
  height: 40px;
  font-size: 11px !important;
  line-height: 16px;
  color: #000;
}

.loader-style {
  align-items: center;
  background: hsla(0, 0%, 100%, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 1200;
}

.sticky-header.white_header .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.chat-height-fix {
  height: 47vh !important;
}

.discover-content a {
  cursor: auto;
}

.provisional-popup .modal-header {
  border: 0;
}

.provisional-popup .modal-body h4 {
  text-align: center;
  font-size: 22px;
  color: #1b95bc;
  margin: 0 0 22px 0;
}

.text_inner {
  text-align: center;
  color: #000;
}

.provisional-popup .modal-footer {
  justify-content: center;
  border-top: 0;
  padding: 10px 0 35px;
}

.provisional-popup .modal-body {
  padding: 1rem 1.5rem;
}

.provisional-popup .modal-footer .confirmation_btn {
  padding: 11px 26px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 0 !important;
  text-transform: uppercase;
}

.provisional-popup .modal-content {
  border-radius: 8px;
}

.pb-100 {
  padding: 100px 0 0 0;
}

.cancel-btn {
  text-align: center;
  background: transparent;
  color: #1b95bc;
  font-weight: 600;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
}

.detailed_popup .modal-footer .confirmation_btn {
  padding: 11px 26px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 0 !important;
  text-transform: uppercase;
  width: 100% !important;
  max-width: unset;
}

.cancel-btn:hover {
  background: transparent;
  text-decoration: underline !important;
  color: #1b95bc;
}

.swelling_btn {
  font-size: 16px !important;
  padding: 13px !important;
}

.userportal_blog li img {
  width: 100% !important;
}

.h-40 .option-btn {
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.symptom_images_inner {
  max-width: 500px;
  margin: 0 auto;
}

.symptom_images_inner .symptom-img {
  width: 48%;
}

.date_width_input input {
  width: 100% !important;
  max-width: 256px;
}

.medical_history_details .accordian_collapse .card-header h5 {
  border-bottom: 0;
  border-radius: 10px;
}

.medical_history_details .personal_inner_sec.dropdown_arrow {
  border-bottom: 0.25px solid #ccd2e3;
}

.medical_history_details .personal_inner_sec.dropdown_arrow.collapsed {
  border: 0;
}

.medical_history_details .save-fix-bottom {
  padding: 16px 10px;
  margin: 0;
}

.medical_history_details .small-save {
  margin: 0;
}

.personal_history_accordian .card {
  cursor: pointer;
}

.according-colum-data li {
  width: 100% !important;
}

.ulcer-options-width {
  flex-direction: column;
}

.ulcer-options-width .questions_options {
  width: 100%;
}

.ulcer-option-inner {
  width: 30%;
}

.ulcer-option-inner .select_option_btn {
  max-width: unset;
  width: 100% !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 14px !important;
}

.ulcer-options-width .options_part ul li {
  margin: 0;
}

.ulcer-options-width .options_part.questions_options ul {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 7px 0;
}

.ulcer_img_logged_in {
  width: 100%;
  max-width: 400px;
}

.disease-card-box img {
  border-radius: 10px 10px 0 0;
}

.choose_option_content li {
  cursor: pointer;
}

.white-btn.signOut_btn:hover {
  color: #fff !important;
}

.border-radius-prop {
  border-radius: 10px !important;
}

.ulcersImgDiv img {
  border-radius: 6px;
  max-width: 200px;
  min-height: 200px;
  object-fit: cover;
      object-position: right;
  margin: 0 10px;
  width: 100%;
  height: 100%;
}
.ques_detail_img img {
  border-radius: 6px;
  max-width: 200px;
  min-height: 200px;
  object-fit: cover;
  margin: 0 10px;
  width: 100%;
  height: 100%;
}

.ques_detail_img {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
img.ulcer_img_logged_in {
  margin: 0 10px 0 0;
  object-position: right;
}

.disease_detail_card img {
  border-radius: 10px 10px 0px 0px !important;
}

.questions_options_inner .colorcc:hover {
  color: #1b9fbc !important;
  background: #fff;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
}

.hm-prblm-sect .prblm_img img {
  width: 400px;
  margin: 0 auto;
  max-height: 230px;
  object-fit: cover;
  height: 100%;
}
.add-more-btn .dark_btn {
  width: 140px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.select_option_btn.width_200 {
  width: 200px !important;
  max-width: unset;
}
.content-center {
  justify-content: center !important;
}
.signOut_btn_hide {
  display: none !important;
}
.upcoming_consultation_table table.table tr td:first-child {
  width: 23%;
}
.upcoming_consultation_table table.table tr td:nth-child(2) {
  width: 15%;
}
.upcoming_consultation_table table.table tr td:nth-child(3) {
  width: 30%;
}
.upcoming_consultation_table table.table tr td:last-child {
  width: 30%;
}
.upcoming_consultation_table table.table tr th:first-child {
  width: 23%;
}
.upcoming_consultation_table table.table tr th:last-child {
  width: 30%;
  padding-left: 0;
}
.upcoming_consultation_table table.table tr th:nth-child(3) {
  width: 30%;
  padding-left: 0;
  padding-right: 0;
}
.upcoming_consultation_table table.table tr th:nth-child(2) {
  width: 15%;
  padding-left: 0;
  padding-right: 0;
}
.upcoming_consultation_table tr.box_prop td p {
  padding: 0;
}
.upcoming_consultation_table table.table tr th:first-child {
  padding: 10px 0 10px 26px;
}
.upcoming_consultation_table tr.box_prop td:first-child p {
  padding-left: 10px;
}

.prescription_table table.table tr th:first-child {
  width: 24%;
  padding: 10px 4px 10px 26px;
}
.prescription_table table.table tr th:nth-child(2) {
  width: 38%;
}
.prescription_table table.table tr th:nth-child(3) {
  width: 18%;
}
.prescription_table table.table tr th:last-child {
  width: 16%;
}
.prescription_table table.table tr td:last-child {
  width: 16%;
}
.prescription_table table.table tr td:first-child {
  width: 24%;
}
.prescription_table table.table tr td:first-child p {
  padding-left: 10px;
}

.prescription_table tr.box_prop td p {
  padding: 10px 4px;
}
table.admin-prescription tr th {
  padding: 10px 4px;
}
.prescription_table table.table tr td:nth-child(3) {
  width: 18%;
}
section.about-us p {
  font-size: 16px;
}
.about-us-title {
  margin: 0 !important;
}
.about-us-title h2 {
  margin: 0 !important;
}
.diagnosis_tabs ul {
  display: flex;
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap !important;
}
.diagnosis_tabs ul li {
  width: 100%;
}
.diagnosis_tabs ul li a {
  padding: 10px;
  display: block;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
  margin: 0 0 13px 0;
  color: #000;
  font-weight: 600;
}
.book_dentist_link {
  margin: 0;
  font-size: 16px;
  color: #1b9fbc;
  font-weight: 600;
  cursor: pointer;
}
.active_tab {
  background: #1b75bc;
  color: #fff !important;
}

.abscess-box-height {
  height: 396px;
}
.abscess-symptom-box {
  height: 260px;
}
.diagnosis_tabs {
  margin: 120px 0 0 0;
  /* margin: 0px 0 0 0; */
  padding: 0 !important;
}
.diagnosis_tabs {
  top: 15%;
  max-width: 420px;
  position: sticky;
  overflow-y: scroll;
  max-height: 600px;
  scrollbar-width: thin;
}
.prescription_lg_table tr.box_prop td p {
  border: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: block;
}
.overflow_table .prescription_lg_table td p {
  height: 26px !important;
}
.prescription_lg_table tr td {
  border: 0.25px solid #ccd2e3;
  border-right: 0;
  padding: 6px 7px 0 4px;
}

.prescription_lg_table tbody tr {
  margin: 10px;
}

.prescription_lg_table tr td:nth-child(2) {
  border-left: 0;
}
.prescription_lg_table tr td:nth-child(3) {
  border-left: 0;
}

.prescription_lg_table tr td:nth-child(4) {
  border-left: 0;
  border-right: 0.25px solid #ccd2e3;
}

.videoPopup .text_inner p {
  font-size: 15px;
  font-weight: 500;
  padding: 0 30px;
}
.videoPopup .modal-content {
  height: 175px;
}
.overflow_table.past_pres tbody {
  max-height: 162px !important;
  overflow: auto;
}
.common_issues_scroll_inner {
  width: 1346px;
  flex-wrap: inherit;
  padding: 0 40px;
  justify-content: flex-start !important;
}
.common_issues_scroll {
  overflow-x: scroll;
  scrollbar-width: thin;
}
.common_issues_scroll::-webkit-scrollbar {
  background-color: unset;
}
.common_issues_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: none;
  background: #F5F5F5 !important;
}
.common_issues_scroll::-webkit-scrollbar-track {
  background-color: unset;
  box-shadow: none;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  /* border-radius: 19px; */
  /* background-color: #F5F5F5; */
}
/* .country_code {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 13px;
} */
.country_code p {
  margin: 0;
}

.phn-form-control {
  background: #efefef;
  border-radius: 5px;
}
.country_code {
  padding: 13px 11px;
  border-right: 1px solid #e5dddd;
}
.country_code p {
  margin: 0;
  color: #7c6d6d;
}

.contact_us_form .card-height-box {
  height: 665px;
  width: 700px;
}

.contact_us_form .form-control:focus {
  background-color: #efefef;
}
.contact_us_form textarea {
  resize: none;
}
.checkbox_input p {
  font-size: 11px;
  color: #555151;
}
.checkbox_input {
  margin: 0 0 6px 0px;
}
.img_width_prblm img {
  width: 100%;
  max-width: 500px;
}
.learn_more_btn_link button {
  margin: 50px 0 0 0;
  border-radius: 4px !important;
}
.overflowScrollProp {
  overflow-y: scroll;
  scrollbar-width: thin;
}
.overflowScrollProp .tab_scroll {
  height: 600px;
}
.diagnosis_tabs::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.overflow_prop.overflowScrollProp::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
.overflow_table.pres_table tbody::-webkit-scrollbar {
  height: 3px;
}
.pres_card_body {
  padding: 1.25rem 1.25rem;
}
.pres_card_body .prescription_lg_table tbody tr {
  margin: 10px 0;
}
.pres_card_body .overflow_table.past_pres tbody {
  max-height: 130px !important;
  overflow: auto;
}

/* rajinder css here  */

img.xray_image {
  height: 150px;
  width: 150px;
  max-height: 100%;
  object-fit: cover;
  margin: 0px 0px 15px 15px;
  border-radius: 15px;
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
}

.img-upload-input-xray {
  background: #ede9e9;
  position: absolute;
  width: 160px;
  height: 100px;
  left: 20%;
  transform: translate(-53%, 282%);
  opacity: 0;
  cursor: pointer;
  top: 0px;
  border-radius: 50px;
}

.xray_label {
  padding: 19px 15px 12px;
  color: #1b9fbc;
}

/* .trustpilot-widget {
  padding-top: 8px;
} */

.new_bl-noon {
  background: #efefef !important;
}


.modal_center, .no_content {
  display: flex;
  justify-content: center;
}

.modal_center {
  margin-top: 20px;
}

.image_xray_view {
  display: flex;
  justify-content: space-between;
}

.image_xray_view button {
  background: #1b9fbc;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 0;
  max-width: 130px;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 12px;
  height: 35px;
  margin: 21px 12px 0px 0px;
}

.modal_center img {
  height: 550px;
  max-width: 100%;
  width: 700px;
  object-fit: cover;
}
.p-50 {
  padding-top: 50px !important;
  padding-bottom: 80px !important;
}
/* .trustpilot-widget  {
  padding-top: 8px;
  background: white;
  height: 85px;
  margin-top: -10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
} */
li.sub-heading-ul {
  margin-top: 10px;
  border-top: 1px solid #eee;
}
li.sub-heading-ul a {
  box-shadow: none !important;
  border: none !important;
  font-size: 12px;
  opacity: 0.5;
}
.diagnosis_tabs1 ul {
  display: flex;
  padding: 0;
  justify-content: space-between;
  flex-wrap: wrap !important;
  flex-direction: column;
}
.diagnosis_tabs1 ul li a {
  padding: 10px;
  display: block;
  box-shadow: 0px 2px 14px rgb(0 0 0 / 10%);
  margin: 0 0 13px 0;
  color: #000;
  font-weight: 600;
}
.discover-content .features img {
	box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.50);
	height: 166px;
	object-fit: cover;
	border-radius: 50%;
}
.discover-content .features h3{
  margin: 25px 0 0 0;
}
.disease_detail_card img {
	height: 280px;
	width: 100%;
	object-fit: cover;
}
.contain_img {
	object-fit: contain !important;
}
.bg_login {
	background: #efefef;
}
.delete-red-text {
  background: #fff;
  box-shadow: none !important;
  border-radius: 10px;
  border-radius: 50px !important;
  padding: 5px 0px !important;
  text-align: center;
  color: #E14942 !important;
  margin-left: 20px;
  width: 104px;
  font-size: 11px;
  border: 1px solid #E14942 !important;
  font-weight: 600;
}
.delete-red-text:hover {
  color: #E14942 !important;
}

.filter-select-element {
  background-image: url(../images/arrow-drop.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center right 20px !important;
  background-size: 13px !important;
}

.filter-select-element {
  border-radius: 11px;
  border: 1.108px solid #DBD8FC;
  background: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px;
}
.filter-select-element::placeholder {
  color: #808080 !important;
}
.filter-input-element::placeholder {
  color: #808080 !important;
}

.td-select-element {
  background-image: url(../images/select-drop-td.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center right 20px !important;
  background-size: 13px !important;
}

.td-select-element {
  border-radius: 11px;
  border: 0px;
  background: #FFF;
  font-size: 12px;
  cursor: pointer;
  font-style: normal;
  width: 150PX;
  font-weight: 700;
  line-height: normal;
 padding: 0;
  color: #000;
}
.td-select-element::placeholder {
  color: #000;
}
.filter-input-element{
  border-radius: 11px;
  border: 1.108px solid #DBD8FC;
  background: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 15px;
}
.popup-input-element .phn-form-control_width {
  width: 100%;
}
.popup-input-element input:focus {
  background-color: #efefef;
}
.submit-btn-popup {
  background: #1b95bc;
  color: #fff;
  margin-left: 20px;
  border-radius: 10px;
  padding: 8px 16px;
  width: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  border: none;
}

.disable_consult {
  cursor: not-allowed;
}

.disable_consult a {
  cursor: not-allowed;
}

.form-control:disabled {
  background-color: transparent!important;
}

.confirm-text {
  font-size: 18px;
}

/* .become-denstist-form {
  margin: 200px 0;
} */
.become-member-part{
  margin-bottom: 80px;
  width: 40%;
}
.become-text-center h1 {
  display: flex;
  justify-content: center;
}

.hover-show {
  display: none;
}
.simple-show:hover .hover-show {
  display: block;
  position: absolute;
  top: 20px;

}
.simple-show:hover button.btn.btn-primary.white-btn.hov-btn {
  opacity: 0;
  visibility: hidden;
}
.pop-form-main h4 {
  margin: 0;
  padding-bottom: 5px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.pop-form-main {
  padding: 10px 25px;
}
.pop-form-main h5 {
  margin: 0;
  padding-bottom: 5px;
      font-size: 14px;
      color: #000;
}

.pop-form-main .form-check {
  padding: 0;
}

.pop-set-btns.modal-footer button {
  background: #1b9fbc;
}
.pop-set-btns.modal-footer button {
  padding: 8px 50px;
  border: none;
  background: #1b9fbc;
}
.banner-p-text{
  font-size: 14px !important;
}
.mb-90{
  margin-bottom: 90px;
}


.privacy_text {
    margin-top: 100px;
}
.privacy_text ul li {
    list-style: disc;
    padding: 4px 0;
}
.privacy_text h5 {
    margin-bottom: 0;
    margin-top: 10px;
}
.privacy_text h3 {
    margin-bottom: 15px;
}
.privacy_text table tr th {
    border: 1px solid #000;
    padding: 10px;
    
}
.privacy_text table tr td {
    border: 1px solid #000;
    padding: 10px;
}

.privacy_text p {
    font-size: 14px;
}
.privacy_text table {
    margin-bottom: 10px;
}

.rating-sec textarea {
   
    padding: 10px 10px;
}
.rating-main-popup button.btn.btn-primary {
    padding: 12px 50px;
    background: #1b9fbc;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
    border: none;
}
.rating-sec h3 {
    font-size: 16px;
    margin-bottom: 13px;
}
.rating-sec h5 {
    font-size: 14px;
}

.rating-sec svg.star-svg {
    width: 35px;
}


.suspend-btn {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;
  color: rgba(67, 24, 255, 1);
  border: 0.5px solid transparent;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  min-width: 220px;
  border-radius: 16px;
  background-color: white;
  padding: 8px 15px;
}

.account-actions {
  display: flex;
  justify-content: center !important;
}

.cancel-btn-popup {
  background: #fff;
  color: #000;
  margin-left: 10px;
  border-radius: 10px;
  padding: 8px 16px;
  width: auto;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  border: none;
}


.border-none {
  border: 0;
}


.are-you-sure-txt {
  margin: 0;
  font-size: 20px;
}
.message-overf-set-inner {
  max-height: 150px !important;
  overflow-x: auto;
}